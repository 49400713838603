.topendpage{
    text-align: center;
     font-family:Daikon-regular;
     /* padding:12px; */
     display: flex;
     justify-content: center;
     margin-top: 3%;
}

.topendpage1{
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
   margin: 1rem 1rem 0rem 1rem;
   padding: 12px; 
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.cardendpage.css-bhp9pd-MuiPaper-root-MuiCard-root{
   max-width: 300;
   padding: 12px;
  
}

.cardendtitle{
   font-size: "larger";
   font-weight: 600;
   color: "black";
   font-family: 'Daikon-regular';
}

.cardendtitle:hover{ 
   color: green;
   box-shadow: none
}

.cardcontent{

}

.css-1c5g2c9-MuiCardMedia-root{
   max-height:150x;  
}

.MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
   width: 25rem;
}


.cardendpage { 
   margin: 1rem; 
}

.buttonendpage{
   display: flex;
   justify-content: center;
   margin-bottom: 2%;
}

@media (max-width: 1424px){
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 23rem;
   }
}


@media (max-width: 1328px) {
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 22rem;
   }
}

@media (max-width: 1280px) {
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 20rem;
   }
}

@media (max-width: 1185px) {
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 18rem;
   }
}

@media (max-width: 1090px) {
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 16rem;
   }
}

@media (max-width: 996px) {
   .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.cardendpage.css-bhp9pd-MuiPaper-root-MuiCard-root{
       width: 100%;
       padding: 12px;   
   }
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 100%;
   }
   .MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img.css-o69gx8-MuiCardMedia-root{
       height: 10rem;
       object-fit: contain;
       display: flex;
       justify-content: center;
   }
}

@media (max-width: 480px) {
   .cardendpage {
       width: 100%; 
   }
}