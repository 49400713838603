.toptestimonial {
    background-color: #05053a;
    padding:0rem 1rem 1rem 0.5rem;
    margin-top: 2rem;
    text-align: center;
    color: white;
    font-family: 'Daikon-regular';
  }
  
  .testimonial-title {
    color: white;
    font-size: 3rem; 
    font-weight: 700;
  }
  
  .testimonial-container {
    margin-top: 0rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .testimonial-box {
    width: 25rem;
    height: 18rem;
    background-color: white;
    margin: 0.5rem;
    border-radius: 15px;
  }
  
  .testimonial-text {
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    color: black;
    text-align: left;
  }
  
  .testimonial-author {
    color: black;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 0.3rem 0 0;
    margin: -0.5rem 0 0 0;
    text-align: left;
  }
  
  .testimonial-author1 {
    color: black;
    font-size: 1rem; 
    font-weight: 600;
    width: 6rem;
    padding-top: 2rem;
  }
  
  .testimonial-author2 {
    color: black;
    font-size: 1rem; 
    font-weight: 600;
    padding: 1rem 0.3rem 0 0;
  }
  
  .testimonial-heading {
    color: black;
    font-size: 2rem; 
    font-weight: bolder;
  }

  
  @media (max-width:1312px){
    .testimonial-box{
        width: 20rem;
        height: 20rem;
    }
    .testimonial-author1{

    }
  }

  @media (max-width:1072px){
    .testimonial-box{
        width: 15rem;
        height: 25rem;
    }
  }

  @media (max-width:834px){
    .testimonial-container {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
      }
      .testimonial-box{
        width: 100%;
        height: 100%;
    }
  }

  @media (max-width:834px){
    .toptestimonial {
    margin-top: 5rem;
  }
}

  @media (max-width:350px){
    .testimonial-title{
        font-size: 2rem;
    }
  }