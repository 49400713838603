/* .head {
  display: flex;

  text-align: center !important;
  justify-content: center;
  margin-top: 70px;
 
  font-family: "Daikon-Bold";
  font-style: normal;
}
.headbutton{
  display: flex;
  justify-content: center;
  text-align: 'center';
  padding-top: '12px';
}
.para {
  text-align: center;
  font-family: "Daikon-Regular";
  margin-left: 6%;
  margin-right: 6%;
}
.buttonmain {
  height: 50px;
  width: 155px;
 
  margin-left: 44%;
  margin-top: 18px;
  margin-bottom: 18px;

  background-color: #35a848;
  color: white;
  border-radius: 10px;
  border-width: 0px;
  font-size: x-large;
  cursor: pointer;
}
.buttonmain1 {
  text-decoration: none;
  color: white;
}

.image-div {
  text-align: center;
  padding: 25px;
  padding-top: 6rem;
}
.imagemain {
  height: 200px;
}
.box1 {
  padding: 25px 0px 0px 25px;
  display: flex;
}
.box11 > p {
  padding: 8px 0px 0px 10px;
  margin: 15px 0px 0px 25px;
  font-weight: bold;
}
.box11 > p:hover {
  color: green;
  cursor: pointer;
}
.box12 > p {
  padding: 8px 0px 0px 10px;
  margin: 15px 0px 0px 25px;
  font-weight: bold;
}
.box12 > p:hover {
  color: green;
  cursor: pointer;
}
.box11 > img {
  background-color: rgb(51, 122, 183);
  margin: 2.5rem 0rem 0rem 0.5rem;
  width: 6rem;
  height: 3.5rem;
}
.box11 {
  border: 1px solid black;
  width: 30vw;
  display: flex;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.box12 > img {
  width: 6rem;
  height: 3.5rem;
  margin: 9px 30px 0px 15px;
  padding: 30px 10px 65px 0px;
}
.box12 {
  border: 1px solid black;
  width: 30vw;
  display: flex;
  margin: 0px 0px 0px 50px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.box12 > h1 {
  font-size: 2.5rem;
  padding: 35px 20px 0px 20px;
  color: green;
}
.box22 > img {
  background-color: rgb(51, 122, 183);
  margin: 2.5rem 0rem 0rem 0.5rem;
  width: 6rem;
  height: 3.5rem;
}
.box22 {
  border: 1px solid black;
  width: 30vw;
  display: flex;
  margin: 0px 0px 0px 50px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.box22 > h1 {
  font-size: 2.5rem;
  padding: 35px 20px 0px 20px;
  color: green;
}
.box22 > p {
  padding: 8px 0px 0px 10px;
  margin: 15px 0px 0px 20px;
  font-weight: bold;
}
.box22 > p:hover {
  color: green;
  cursor: pointer;
}
.boxes {
  display: flex;
  justify-content: center;
  padding: 12px;
  
}
.boxes2 {
  background-color: #e7eefe;
 
  border-radius: 1rem;
  font-family: "Daikon-regular";
  position: static;
}
.boxes21 {
  height: 100%;
  border: 1px solid black;
  width: 22vw;
  border-radius: 10px;
  border: 1px solid lightgrey;
  overflow: none;
}
.boxes21 > img {
  width: 5rem;
  height: 10rem;
}
.boxes21 > h1 {
 
  text-align: center;
  font-size: x-large;
}
.boxes21 > div > p {

  text-align: center;
}
.buttmycard {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.butmycard {
  background-color: #14213d;
  color: white;
  text-align: center;
  height: 2.5rem;
  width: 15rem;
  text-align: center;
  font-size: x-large;
  border: none;
  border-radius: 7px;
  font-weight: bold;
  cursor: pointer;
}
.buttmycard > img {
  height: 15rem;
  width: 15rem;
  margin: 3rem 0rem 0rem 1rem;
}
.bottombutton {


  border: none;
  border-radius: 9px;
  background-color: white;
  color: #14213d;
  border: 2px solid #14213d;
  height: 2.5rem;
  width: 12.5rem;
  font-size: 1.5rem;
  font-family: "Daikon-regular";
}
.bottombutton:hover {
  border: 2px solid #35a848;
}

.inputmain {
  width: 50%;
 
  height: 3rem;
  border-radius: 10px;
  font-size: larger;
  
  padding: 0 0.5rem;
  font-family: "Daikon-regular";

}

.inputmain:focus {

  border: 3px solid #35a848;
}



.searchbar {


  width: 100%;
  
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  margin: 3rem 0rem 0rem 0rem;
}
.buttonNav {
  height: 40px;
  width: 74px;
  margin: 2.5rem 0rem 0rem 1rem;
  text-align: center;
  color: white;
  background-color: black;
  border-radius: 10px;
}

.image {
  height: 20px;
  position: absolute;
  margin: 3.3rem 0rem 0rem 60rem;
 
}
.cardviewmorebutton {
  text-decoration: none;
  color: black;
}
.cardviewmorebutton:hover {
  color: green;
}
.mainbodycard {
  margin: 0rem 0rem 0rem 1rem;
  margin: 2rem 0rem 0rem 2rem;
}

.mainbodycard1 {
  text-decoration: none;
  color: black;
  font-size: large;
  font-family: "Daikon-Regular";
}
.mainbodycard1:hover {
  color: green;
}
.box12image > img {
  background-color: #337ab7;
  margin: 2.5rem 0rem 0rem 0.5rem;
  width: 6rem;
  height: 3.5rem;
}

.carouselDiv {
  transition: box-shadow 1s;
}

.carouselDiv:hover {
  
  box-shadow: 3px 3px #35a848;
  cursor: pointer;
}

.MuiTypography-root.MuiTypography-body1.owlText.css-ahj2mt-MuiTypography-root{
  margin: 0rem 3rem 1rem 3rem;
}



.square {
  width: 10rem;
  height: 10rem;
  position: relative;
  margin: auto;
}
.square::before,
.square::after {
  content: "";
  position: absolute;
  background-color: black;
}
.square::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: -1;
}
.square::after {
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  z-index: -1; 
}
.left-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: black;
}
.dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #ed3125;
  border-radius: 50%;
}
.top-dot {
  top: -3px;
  right: -3px;
}
.bottom-dot {
  bottom: -3px;
  right: -3px;
}





.number {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ed3125;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  box-sizing: border-box; 
}
.number-top {
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}
.number-bottom {
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
.outer-circle {
  width: 50px;
  height: 50px;
  border: 2px solid rgb(242, 231, 231); 
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translate(-50%, -50%);
  z-index: -1; 
  box-sizing: border-box; 
}

.left-text {
  position: absolute;
  left: -21rem; 
  right: 200px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  width: 20rem;
}

.center-text {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: rgb(82, 72, 72);
  font-weight: 300px;
}

.square2 {
  width: 150px;
  height: 150px;
  position: relative;
  margin: auto;
  transform: scaleX(-1); 
}
.square2::before,
.square2::after {
  content: "";
  position: absolute;
  background-color: black;
}
.square2::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: -1; 
  transform: scaleX(-1); 
}
.square2::after {
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  z-index: -1; 
  transform: scaleX(-1); 
}
.left-line2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: black;
  transform: scaleX(-1); 
}
.dot2 {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: rgb(57, 95, 210);
  border-radius: 50%;
  transform: scaleX(-1); 
}
.top-dot2 {
  top: -3px;
  right: -3px;
}
.bottom-dot2 {
  bottom: -3px;
  right: -3px;
}
.number2 {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgb(57, 95, 210);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  box-sizing: border-box; 
  transform: scaleX(-1); 
  left: 50%;
  top: 50%; 
  transform-origin: center; 
  transform: translate(-50%, -50%) scaleX(-1); 
}
.number-top2 {
  left: 0px;
}
.number-bottom2 {
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
} 
.outer-circle2 {
  width: 50px;
  height: 50px;
  border: 2px solid rgb(242, 231, 231); 
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 67%; 
  transform: translate(-50%, -50%) scaleX(-1); 
  z-index: -1; 
  box-sizing: border-box; 
}
.left-text2 {
  position: absolute;
  left: -515px;
  right: 280px;
  top: 20%;
  height: 20px;
  width: 20rem;
  transform: translateX(50%) scaleX(-1);
  font-size: 14px;
  direction: ltr; 
  unicode-bidi: bidi-override; 
}

.center-text2 {
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%) scaleX(-1); 
  font-size: 20px;
  color: rgb(82, 72, 72);
  font-weight: 300px;
}

.boxmainbody{
  padding: 16px; 
  padding-bottom: 12px; 
  margin-bottom:1rem ;
}

.bodyinsidetop{
  width: 100%;
  height: 100%;
  background-color: #EEF5F9;
  overflow: hidden;
  font-family: Daikon-regular; 
  text-align: center;
 
}




@media (max-width:850px){
  .left-text{
    width: 15rem;
    left: -16rem;
  }
  .left-text2{
    width: 13rem;
    top: 0;
    left: -22rem;
  }
  .square{
    height:11rem;
  }
  .square2{
    height: 175px;
  }
}

@media (max-width:690px){
  .left-text{
    width: 12rem;
    left: -12rem
  }
  .left-text2{
    width: 12rem;
    left: -20rem;
  }
  .square{
    height:12rem;
  }
  .image-div{
    display: none;
  }
}

@media (max-width:570px){
  .left-text{
    width: 10rem;
    left: -10rem;
  }
  .left-text2{
    width: 10rem;
    left: -16rem;
  }
  .square{
    height:14rem;
  }
  .square2{
    height: 220px;
  }
  .boxmainbody{
    padding: 8px;
  }
  .bodyinsidetop{
    margin-top: 15%;
  }
}


@media (max-width:500px){
  .boxmainbody{
    padding: 2px;
  }
  .left-text {
    width: 7rem;
    left: -7rem;
}
  .square2 {
    height: 310px;
}

  .left-text2{
    width: 7rem;
    left: -12rem;
  }
}


@media (max-width:430px){
  .square2 {
    height: 414px;
}
  .left-text2 {
    width: 4rem;
    left: -8rem;
}
.left-text {
  width: 5rem;
  left: -7rem;
}
.square {
  height: 18rem;
}
.bodyinsidetop{
  margin-top: 20%;
}
}


@media (max-width:410px){
  .bodyinsidetop{
    margin-top: 30%;
  }
}


@media (max-width:400px){
  .square {
    width: 7rem;
  }
  .square2 {
    width: 114px;
  }
}

@media (max-width:380px){
  .bodyinsidetop{
    margin-top: 40%;
  }
}

@media (max-width:350px){
  .bodyinsidetop{
    margin-top: 50%;
  }
}

@media (max-width:340px){
  .square {
    width: 5rem;
  }
  .square2 {
    width: 83px;
  }
  .center-text{
    left: 86%;
  }
  .outer-circle2{
    right: 43%;
  }
  .center-text2{
    left: 78%;
  }
}


@media (max-width:325px){
  .bodyinsidetop{
    margin-top: 70%;
  }
}


.bodyinsidetop {
  text-align: center;
  padding: 20px;
}



.owl-carousel .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carouselDiv {
  max-width: 100%;
  height: auto;
}

@media (max-width: 600px) {
  .carouselDiv {
    width: 80%;
  }
}

@media (min-width: 600px) {
  .carouselDiv {
    width: 70%;
  }
}

@media (min-width: 960px) {
  .carouselDiv {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .carouselDiv {
    width: 50%;
  }
}

@media (min-width: 1400px) {
  .carouselDiv {
    width: 40%;
  }
} */

.head {
  display: flex;
  /* margin: 1rem 0rem 1rem 0rem; */
  text-align: center !important;
  justify-content: center;
  margin-top: 70px;
  /* padding-left: 12%; */
  font-family: "Daikon-Bold";
  font-style: normal;
}
.headbutton{
  display: flex;
  justify-content: center;
  text-align: 'center';
  padding-top: '12px';
}
.para {
  text-align: center;
  font-family: "Daikon-Regular";
  margin-left: 6%;
  margin-right: 6%;
}
.buttonmain {
  height: 50px;
  width: 155px;
  /* margin: 25px 0px 0px 575px; */
  margin-left: 44%;
  margin-top: 18px;
  margin-bottom: 18px;
  /* padding-top: ; */
  background-color: #35a848;
  color: white;
  border-radius: 10px;
  border-width: 0px;
  font-size: x-large;
  cursor: pointer;
}
.buttonmain1 {
  text-decoration: none;
  color: white;
}

.image-div {
  text-align: center;
  padding: 25px;
  padding-top: 6rem;
}
.imagemain {
  height: 200px;
}
.box1 {
  padding: 25px 0px 0px 25px;
  display: flex;
}
.box11 > p {
  padding: 8px 0px 0px 10px;
  margin: 15px 0px 0px 25px;
  font-weight: bold;
}
.box11 > p:hover {
  color: green;
  cursor: pointer;
}
.box12 > p {
  padding: 8px 0px 0px 10px;
  margin: 15px 0px 0px 25px;
  font-weight: bold;
}
.box12 > p:hover {
  color: green;
  cursor: pointer;
}
.box11 > img {
  background-color: rgb(51, 122, 183);
  margin: 2.5rem 0rem 0rem 0.5rem;
  width: 6rem;
  height: 3.5rem;
}
.box11 {
  border: 1px solid black;
  width: 30vw;
  display: flex;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.box12 > img {
  width: 6rem;
  height: 3.5rem;
  margin: 9px 30px 0px 15px;
  padding: 30px 10px 65px 0px;
}
.box12 {
  border: 1px solid black;
  width: 30vw;
  display: flex;
  margin: 0px 0px 0px 50px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.box12 > h1 {
  font-size: 2.5rem;
  padding: 35px 20px 0px 20px;
  color: green;
}
.box22 > img {
  background-color: rgb(51, 122, 183);
  margin: 2.5rem 0rem 0rem 0.5rem;
  width: 6rem;
  height: 3.5rem;
}
.box22 {
  border: 1px solid black;
  width: 30vw;
  display: flex;
  margin: 0px 0px 0px 50px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.box22 > h1 {
  font-size: 2.5rem;
  padding: 35px 20px 0px 20px;
  color: green;
}
.box22 > p {
  padding: 8px 0px 0px 10px;
  margin: 15px 0px 0px 20px;
  font-weight: bold;
}
.box22 > p:hover {
  color: green;
  cursor: pointer;
}
.boxes {
  display: flex;
  justify-content: center;
  padding: 12px;
  /* flex-wrap: wrap; */
}
.boxes2 {
  background-color: #e7eefe;
  /* margin: 25px 0px 0px 10px;
    border-radius: 10px ; */
  /* margin: 1rem 1rem 0rem 2rem; */
  border-radius: 1rem;
  font-family: "Daikon-regular";
  position: static;
}
.boxes21 {
  height: 100%;
  border: 1px solid black;
  width: 22vw;
  border-radius: 10px;
  border: 1px solid lightgrey;
  overflow: none;
}
.boxes21 > img {
  width: 5rem;
  height: 10rem;
}
.boxes21 > h1 {
  /* padding: 30px 0px 0px 75px; */
  text-align: center;
  font-size: x-large;
}
.boxes21 > div > p {
  /* padding: 10px 0px 0px 40px; */
  text-align: center;
}
.buttmycard {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.butmycard {
  background-color: #14213d;
  color: white;
  text-align: center;
  height: 2.5rem;
  width: 15rem;
  text-align: center;
  font-size: x-large;
  border: none;
  border-radius: 7px;
  font-weight: bold;
  cursor: pointer;
}
.buttmycard > img {
  height: 15rem;
  width: 15rem;
  margin: 3rem 0rem 0rem 1rem;
}
.bottombutton {
  /* margin: 30px 0px 20px 500px; */

  border: none;
  border-radius: 9px;
  background-color: white;
  color: #14213d;
  border: 2px solid #14213d;
  height: 2.5rem;
  width: 12.5rem;
  font-size: 1.5rem;
  font-family: "Daikon-regular";
}
.bottombutton:hover {
  border: 2px solid #35a848;
}

.inputmain {
  width: 50%;
  /* text-align: left; */
  height: 3rem;
  border-radius: 10px;
  font-size: larger;
  /* padding: 0rem 0rem 0rem 0.5rem; */
  padding: 0 0.5rem;
  font-family: "Daikon-regular";
  /* display: flex; */
}

.inputmain:focus {
  /* border-width: 3px;
  border-color:#35a848; */
  border: 3px solid #35a848;
}

/* .SearchbarMain {
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
} */

.searchbar {
  /* position: relative; */

  width: 100%;
  /* padding: 5em 0em 2em 14em; */
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  margin: 3rem 0rem 0rem 0rem;
}
.buttonNav {
  height: 40px;
  width: 74px;
  margin: 2.5rem 0rem 0rem 1rem;
  text-align: center;
  color: white;
  background-color: black;
  border-radius: 10px;
}

.image {
  height: 20px;
  position: absolute;
  margin: 3.3rem 0rem 0rem 60rem;
  /* margin: 51px 0px 0px -818px; */
  /* margin: 3.2rem 0rem 0rem -48.5rem; */
}
.cardviewmorebutton {
  text-decoration: none;
  color: black;
}
.cardviewmorebutton:hover {
  color: green;
}
.mainbodycard {
  margin: 0rem 0rem 0rem 1rem;
  margin: 2rem 0rem 0rem 2rem;
}

.mainbodycard1 {
  text-decoration: none;
  color: black;
  font-size: large;
  font-family: "Daikon-Regular";
}
.mainbodycard1:hover {
  color: green;
}
.box12image > img {
  background-color: #337ab7;
  margin: 2.5rem 0rem 0rem 0.5rem;
  width: 6rem;
  height: 3.5rem;
}

.carouselDiv {
  transition: box-shadow 1s;
}

.carouselDiv:hover {
  /* padding-top: 12px; */
  box-shadow: 3px 3px #35a848;
  cursor: pointer;
}

.MuiTypography-root.MuiTypography-body1.owlText.css-ahj2mt-MuiTypography-root{
  margin: 0rem 3rem 1rem 3rem;
}

/* .owlText{
  margin: 0rem 3rem 1rem 3rem;
} */

.square {
  width: 10rem;
  height: 10rem;
  position: relative;
  margin: auto;
}
.square::before,
.square::after {
  content: "";
  position: absolute;
  background-color: black;
}
.square::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: -1;
}
.square::after {
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  z-index: -1; 
}
.left-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: black;
}
.dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #ed3125;
  border-radius: 50%;
}
.top-dot {
  top: -3px;
  right: -3px;
}
.bottom-dot {
  bottom: -3px;
  right: -3px;
}
.number {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ed3125;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  box-sizing: border-box; 
}
.number-top {
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}
.number-bottom {
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
.outer-circle {
  width: 50px;
  height: 50px;
  border: 2px solid rgb(242, 231, 231); 
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translate(-50%, -50%);
  z-index: -1; 
  box-sizing: border-box; 
}

.left-text {
  position: absolute;
  left: -21rem; 
  right: 200px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  width: 20rem;
}

.center-text {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: rgb(82, 72, 72);
  font-weight: 300px;
}

.square2 {
  width: 150px;
  height: 150px;
  position: relative;
  margin: auto;
  transform: scaleX(-1); 
}
.square2::before,
.square2::after {
  content: "";
  position: absolute;
  background-color: black;
}
.square2::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: -1; 
  transform: scaleX(-1); 
}
.square2::after {
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  z-index: -1; 
  transform: scaleX(-1); 
}
.left-line2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: black;
  transform: scaleX(-1); 
}
.dot2 {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: rgb(57, 95, 210);
  border-radius: 50%;
  transform: scaleX(-1); 
}
.top-dot2 {
  top: -3px;
  right: -3px;
}
.bottom-dot2 {
  bottom: -3px;
  right: -3px;
}
.number2 {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgb(57, 95, 210);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  box-sizing: border-box; 
  transform: scaleX(-1); 
  left: 50%;
  top: 50%; 
  transform-origin: center; 
  transform: translate(-50%, -50%) scaleX(-1); 
}
.number-top2 {
  left: 0px;
}
.number-bottom2 {
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
} 
.outer-circle2 {
  width: 50px;
  height: 50px;
  border: 2px solid rgb(242, 231, 231); 
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 67%; 
  transform: translate(-50%, -50%) scaleX(-1); 
  z-index: -1; 
  box-sizing: border-box; 
}
.left-text2 {
  position: absolute;
  left: -515px;
  right: 280px;
  top: 20%;
  height: 20px;
  width: 20rem;
  transform: translateX(50%) scaleX(-1);
  font-size: 14px;
  direction: ltr; 
  unicode-bidi: bidi-override; 
}

.center-text2 {
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%) scaleX(-1); 
  font-size: 20px;
  color: rgb(82, 72, 72);
  font-weight: 300px;
}

.boxmainbody{
  padding: 16px; 
  padding-bottom: 12px; 
  margin-bottom:1rem ;
}

.bodyinsidetop{
  width: 100%;
  height: 100%;
  background-color: #EEF5F9;
  overflow: hidden;
  font-family: Daikon-regular; 
  text-align: center;
  margin-top: 10% ;
}




@media (max-width:850px){
  .left-text{
    width: 15rem;
    left: -16rem;
  }
  .left-text2{
    width: 13rem;
    top: 0;
    left: -22rem;
  }
  .square{
    height:11rem;
  }
  .square2{
    height: 175px;
  }
}

@media (max-width:690px){
  .left-text{
    width: 12rem;
    left: -12rem
  }
  .left-text2{
    width: 12rem;
    left: -20rem;
  }
  .square{
    height:12rem;
  }
  .image-div{
    display: none;
  }
}

@media (max-width:570px){
  .left-text{
    width: 10rem;
    left: -10rem;
  }
  .left-text2{
    width: 10rem;
    left: -16rem;
  }
  .square{
    height:14rem;
  }
  .square2{
    height: 220px;
  }
  .boxmainbody{
    padding: 8px;
  }
  .bodyinsidetop{
    margin-top: 15%;
  }
}


@media (max-width:500px){
  .boxmainbody{
    padding: 2px;
  }
  .left-text {
    width: 7rem;
    left: -7rem;
}
  .square2 {
    height: 310px;
}

  .left-text2{
    width: 7rem;
    left: -12rem;
  }
}


@media (max-width:430px){
  .square2 {
    height: 414px;
}
  .left-text2 {
    width: 4rem;
    left: -8rem;
}
.left-text {
  width: 5rem;
  left: -7rem;
}
.square {
  height: 18rem;
}
.bodyinsidetop{
  margin-top: 20%;
}
}


@media (max-width:410px){
  .bodyinsidetop{
    margin-top: 30%;
  }
}


@media (max-width:400px){
  .square {
    width: 7rem;
  }
  .square2 {
    width: 114px;
  }
}

@media (max-width:380px){
  .bodyinsidetop{
    margin-top: 40%;
  }
}

@media (max-width:350px){
  .bodyinsidetop{
    margin-top: 50%;
  }
}

@media (max-width:340px){
  .square {
    width: 5rem;
  }
  .square2 {
    width: 83px;
  }
  .center-text{
    left: 86%;
  }
  .outer-circle2{
    right: 43%;
  }
  .center-text2{
    left: 78%;
  }
}


@media (max-width:325px){
  .bodyinsidetop{
    margin-top: 70%;
  }
  .square{

  }
  .center-text {
    bottom: -46%;
    width: 14rem;
    left: 100%;
} 
  .left-text {
      width: 7rem;
      left: 0rem;
      top: 52%;
  }
  .left-line{
    display: none;
  }
  .dot.top-dot{
    display: none;
  }
  .dot.bottom-dot{
    display: none;
  }
  .outer-circle{
    display: none;
  }
  .number.number-top{
    display: none;
  }
  .left-line2{
    display: none;
  }
  .dot2.top-dot2{
    display: none;
  }
  .dot2.bottom-dot2{
    display: none;
  }
  .outer-circle2{
    display: none;
  }
  .number2.number-top2{
    display: none;
  }
  .center-text2 {
    left: 17%;
    top: 7%;
    width: 12rem;
}
.left-text2 {
  width: 9rem;
  left: -135%;
  top: 16%;
}
.square2 {
  width: 83px;
  height: 245px;
}
}
/* .main1 {
  display: flex;
  
  background-color: #fff;
  padding: 6em 5em 3em 1em;
  margin: 1em 1em 1em 1em;
  border-radius: 15px;
}
.main1 > img {
  margin: -3rem 0rem 0rem 0rem;
}
.main11 {
  padding: 0rem 4rem 0rem 2rem;
  margin-left: 3rem;
  margin-right: 2rem;
  width: 50%;
  font-family: "Daikon-regular";
} */

.main1 {
  display: flex;
  background-color: #fff;
  padding: 6em 5em 3em 1em;
  margin: 1em;
  border-radius: 15px;
  flex-wrap: wrap; /* Allow items to wrap */
}

.main1 > img {
  max-width: 100%; /* Ensure image does not exceed container width */
  height: auto; /* Maintain aspect ratio */
  margin-top: -3rem; /* Adjust top margin as needed */
}

.main11 {
  padding: 0rem 2rem; /* Adjust padding for content */
  width: 50%;
  font-family: "Daikon-regular";
  flex: 1; /* Take remaining space */
  min-width: 0; /* Allow content to shrink */
}

@media (max-width: 768px) {
  .main1 {
    padding: 4em 2em 2em 1em; /* Adjust padding for smaller screens */
  }

  .main1 > img {
    max-width: 100%; /* Ensure image does not exceed container width */
    height: auto; /* Maintain aspect ratio */
    margin-top: 0; /* Reset top margin */
    margin-bottom: 2em; /* Optional: Add some bottom margin for spacing */
  }

  .main11 {
    padding: 0rem 1rem; /* Adjust padding for smaller screens */
    width: 100%; /* Full width for content on mobile */
  }
}
