/* .about{
    background-color: #EEF5F9;
    display: flex;
    height: 25rem;
    margin: 2rem 1rem 2rem 1rem;
    border-radius: 15px;
}

.about>img{
    width: 24rem;
    height: 10rem;
    margin: 1rem 4rem 1rem 6rem;
}
.about1{
    padding: 0.5rem 1rem 0.5rem 2rem ;
}
.about11{
    display: flex;
    padding: 1rem 1rem 1rem 0rem ;
}
.about11>p{
 
    padding: 0;
    margin: 0rem 1rem 0rem 1rem;
} */

.about {
    text-align: center;
    padding: 20px;
  }
  
  .about1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .about11 {
    flex: 1 1 300px; /* Adjust the size of the boxes as needed */
  }
  
  .about-box {
    background-color: #fff;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  

.aboutbelow{
    background-color: #EEF5F9;
    display: flex;
    height: 15rem;
    margin: 2rem 1rem 2rem 1rem;
    border-radius: 15px;
}
.aboutbelow1{
    padding: 0.5rem 1rem 0.5rem 2rem ;
}
.aboutbelow11{
    display: flex;
    padding: 0.5rem 1rem 0.5rem 0rem ;
}
.aboutbelow11>p{
    /* padding: 0em 0em 0em 0em; */
    padding: 0;
    margin: 0rem 1rem 0rem 1rem;
}

.mediacover1 {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.image-container {
  width: 35%;
  overflow: hidden;
}

.article-image {
  width: 100%;
  height: auto;
  display: block;
}

.mediacover11 {
  flex: 1;
  margin-left: 2%;
}

.letsbuild11 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.letsbuild11 p {
  margin: 0;
  padding-left: 0.5rem; /* Adjust padding as needed */
}

/* Media query for tablets */
@media (max-width: 768px) {
  .image-container {
    width: 100%;
    margin-bottom: 1rem;
  }

  .mediacover11 {
    margin-left: 0;
  }

  .mediacover1 {
    flex-direction: column;
    align-items: stretch;
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .image-container {
    width: 100%;
    margin-bottom: 1rem;
  }

  .mediacover11 {
    margin-left: 0;
  }

  .mediacover1 {
    flex-direction: column;
    align-items: stretch;
  }

  h1 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 1rem;
  }
}


  
   /* .mediacover {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .mediacover1 {
    display: flex;
    align-items: center;
    margin: 20px;
    flex-basis: 45%;
    padding: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  }
  
  .mediacover11 {
    flex: 1;
  }
  
  .letsbuild11 {
    display: flex;
    align-items: center;
    margin-top: 10px;
  } */