.decktop>hr{
    width: 60em;
    margin: 1em 0em 0em 15em;
    color: darkgrey;
}
.decktop>h1{
    font-size: xxx-large;
    font-weight: 700;
    padding: 2em 0em 0em 4em;
}
.decktop>p{
    font-size: large;
    padding: 1em 1em 1em 22em;
    text-align: left;
}
.decktop>img{
    width: 20em;
    padding: 3em 0em 0em 0em;
    margin: 1em 0em 1em 30em;
}
/* .letsbuild{
    background-color: #EEF5F9;
    display: flex;
    height: 15rem;
    margin: 2rem 1rem 2rem 1rem;
    border-radius: 15px;
}
.letsbuild>img{
    width: 24rem;
    height: 10rem;
    margin: 1rem 4rem 1rem 6rem;
}
.letsbuild1{
    padding: 0em 0em 0em 2em ;
}
.letsbuild11{
    display: flex;
}
.letsbuild11>p{
    padding: 0em 0em 0em 0em;
}
.raisefund{
    display: flex;
    padding: 2rem 1rem 0rem 1rem;
    margin-bottom: 3%;
}
.raisefund>img{
    width: 24rem;
    height: 10rem;
    margin: 2rem 0rem 1rem 1rem
}
.raisefund1{
    padding: 0rem 1rem 0rem 5rem;
}
.raisefund1>h1{
    font-weight: 700;
}
.raisingsme{
    display: flex;
    padding: 2rem 4rem 3rem 1rem ;
}
.raisingsme>img{
    width: 24rem;
    height: 10rem;
    margin: 2rem 0rem 1rem 3rem;
}
.raisingsme1>p{
    padding: 0em 3em 1em 0em;
}
.raisingsme1>h1{
    font-weight: 700;
}
.raisefundvia>h1{
    padding: 2em 0em 0em 9em;
    font-weight: 700;
}
.raisefundvia>p{
    padding: 2em 0em 0em 28em;
}
.bigboxipofund{
    display: flex;
    justify-content: space-evenly;
    padding: 5em 0em 0em 1em;
}
#greendotfund{
    margin: 0em 0em 0em 2em ;
}
#greendotfund3{
    margin: 0em 0em 0em 4em ;
}
.fundraisingstories{
    background-color: #EEF5F9;
    height: 25em;
    margin: 2em 0em 2em 0em ;
}
.fundraisingstories>h1{
    padding: 3em 0em 0em 8em;
    font-weight: 700;
}
.fundraisingstories1{
    display: flex;
    justify-content: space-evenly;
    margin: 5em 1em 0em 1em ;
}
.fundraisingstories11{
    background-color: white;
    border-radius: 10px;
    width: 10em;
    height: 7em ;
}
.fundraisingstories11>img{
    width: 5em;
    padding: 2em 2em 0em 3em ;
}
#fundraisingstories114{
    width: 10em;
    height: 7em ;
   
}
#fundraisingstories115{
    width: 10em;
    height: 7em ;
  
}
.reach{
    display: flex;
}
#para2{
    margin: 0em 0em 0em 7em;
    font-size: x-large;
    color: darkgray;
}
#para1{
    font-size: x-large;
}
.reach11{
    display: flex;
    flex-direction: column;
}
.reach11>input{
    width: 35em;
    margin: 0.3em 0em 1em 0em;
    border: 2px solid lightgray;
    border-radius: 8px;
    height: 3em;
}
.reach11>label{
    font-weight: 700;
}
.reach1{
    display: flex;
}
.reachmain{
    padding: 4em 0em 2em 20em;
}
.reachmain>h1{
    padding: 0em 0em 2em 4em ;
}
.reachmain>hr{
    margin: 0em 30em 1em 0em;
}
#reach111{
     margin: 0em 1em 2em 0em;
}
#reach111>input{
    width: 17em;
}
.accordianraisingfunds>h1{
    margin: 2em 0em 0em 7em;
    font-size: xxx-large;
}
.accordianraisingfunds>p{
    margin: 1em 1em 3em 23em;
} */


.topendpage{
    text-align: center;
     font-family:Daikon-regular;
     /* padding:12px; */
     display: flex;
     justify-content: center;
     margin-top: 3%;
}

/* .topendpage1{
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
   margin: 1rem 1rem 0rem 1rem;
   padding: 12px; 
} */

.raisefund {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .raisefundpage {
    flex: 1 1 calc(25% - 20px); 
    margin: 10px;
    box-sizing: border-box;
  }
  

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.cardendpage.css-bhp9pd-MuiPaper-root-MuiCard-root{
   max-width: 300;
   padding: 12px;
  
}

.cardendtitle{
   font-size: "larger";
   font-weight: 600;
   color: "black";
   font-family: 'Daikon-regular';
}

.cardendtitle:hover{ 
   color: green;
   box-shadow: none
}

.cardcontent{

}

.css-1c5g2c9-MuiCardMedia-root{
   max-height:150x;  
}

.MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
   width: 25rem;
}


.cardendpage { 
   margin: 1rem; 
}

.buttonendpage{
   display: flex;
   justify-content: center;
   margin-bottom: 2%;
}

@media (max-width: 1424px){
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 23rem;
   }
}


@media (max-width: 1328px) {
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 22rem;
   }
}

@media (max-width: 1280px) {
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 20rem;
   }
}

@media (max-width: 1185px) {
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 18rem;
   }
}

@media (max-width: 1090px) {
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 16rem;
   }
}

@media (max-width: 996px) {
   .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.cardendpage.css-bhp9pd-MuiPaper-root-MuiCard-root{
       width: 100%;
       padding: 12px;   
   }
   .MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
       width: 100%;
   }
   .MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img.css-o69gx8-MuiCardMedia-root{
       height: 10rem;
       object-fit: contain;
       display: flex;
       justify-content: center;
   }
}

@media (max-width: 480px) {
   .cardendpage {
       width: 100%; 
   }
}

.main1 {
    display: flex;
    background-color: #fff;
    padding: 6em 5em 3em 1em;
    margin: 1em;
    border-radius: 15px;
    flex-wrap: wrap; /* Allow items to wrap */
  }
  
  .main1 > img {
    max-width: 100%; /* Ensure image does not exceed container width */
    height: auto; /* Maintain aspect ratio */
    margin-top: -3rem; /* Adjust top margin as needed */
  }
  
  .main11 {
    padding: 0rem 2rem; /* Adjust padding for content */
    width: 50%;
    font-family: "Daikon-regular";
    flex: 1; /* Take remaining space */
    min-width: 0; /* Allow content to shrink */
  }
  
  @media (max-width: 768px) {
    .main1 {
      padding: 4em 2em 2em 1em; /* Adjust padding for smaller screens */
    }
  
    .main1 > img {
      max-width: 100%; /* Ensure image does not exceed container width */
      height: auto; /* Maintain aspect ratio */
      margin-top: 0; /* Reset top margin */
      margin-bottom: 2em; /* Optional: Add some bottom margin for spacing */
    }
  
    .main11 {
      padding: 0rem 1rem; /* Adjust padding for smaller screens */
      width: 100%; /* Full width for content on mobile */
    }
  }
  

  /* .mediacover > h1 {
    text-align: center;
    font-size: xxx-large;
  }
  .mediacover > h5 {
    text-align: center;
  }
  .mediacover1 {
    display: flex;
    
    background-color: aliceblue;
    padding: 6em 5em 3em 1em;
    margin: 1em 1em 1em 1em;
    border-radius: 15px;
  }
  .mediacover1 > img {
    margin: -3rem 0rem 0rem 0rem;
  }
  .mediacover11 {
    padding: 0rem 4rem 0rem 2rem;
    margin-left: 3rem;
    margin-right: 2rem;
    width: 50%;
    font-family: "Daikon-regular";
  }
  
  .letsbuild11 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .letsbuild11 p {
    margin: 0;
    padding-left: 0.5rem; 
  } */

  /* .mediacover1 {
    display: flex;
    align-items: flex-start;
  }
  
  .image-container {
    width: 35%;
    overflow: hidden;
  }
  
  .article-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .mediacover11 {
    flex: 1;
    margin-left: 2%;
  }
  
  .letsbuild11 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .letsbuild11 p {
    margin: 0;
    padding-left: 0.5rem; 
  } */
  

  .mediacover1 {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }
  
  .image-container {
    width: 35%;
    overflow: hidden;
  }
  
  .article-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .mediacover11 {
    flex: 1;
    margin-left: 2%;
  }
  
  .letsbuild11 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .letsbuild11 p {
    margin: 0;
    padding-left: 0.5rem; /* Adjust padding as needed */
  }
  
  /* Media query for tablets */
  @media (max-width: 768px) {
    .image-container {
      width: 100%;
      margin-bottom: 1rem;
    }
  
    .mediacover11 {
      margin-left: 0;
    }
  
    .mediacover1 {
      flex-direction: column;
      align-items: stretch;
    }
  }
  
  /* Media query for mobile devices */
  @media (max-width: 480px) {
    .image-container {
      width: 100%;
      margin-bottom: 1rem;
    }
  
    .mediacover11 {
      margin-left: 0;
    }
  
    .mediacover1 {
      flex-direction: column;
      align-items: stretch;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    h3 {
      font-size: 1.2rem;
    }
  
    p {
      font-size: 1rem;
    }
  }
  
  