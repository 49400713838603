/* .blocks {
  display: flex;
  flex-direction: row;
  background-color: #14213d;
  color: white;
  overflow: hidden;
  padding-bottom: 1%;
}
.block1 {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 0rem 1rem;
}
.block1 > img {
  background-color: white;
  width: 10rem;
  height: 4rem;
  margin: 1rem 1rem 0rem 2rem;
  object-fit: contain;
}
.block1 > p {
  width: 45rem;
  padding: 1rem 0rem 0rem 2rem;
  font-size: medium;
}
.app {
  padding: 1rem 0rem 0rem 1rem;
  width: 5rem;
}

.anchor {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 200px;
  margin: 0px 10px 0px 100px;
}
.insideanch {
  font-size: medium;
  text-decoration: none;
  color: white;
  flex-direction: column;
  padding: 0.7rem 0rem 0rem 0rem;
  transition: 0.7s ease-in-out;
  transform: translateX(0rem);
}
.insideanch:hover {
  color: green;
  transform: translateX(1.5rem);
}
.block2 {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 0rem 1rem;
}
.block3 {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 0rem 1rem;
}
.block4 {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 0rem 13rem;
  text-align: left;
}

.anchorfoot {
  padding: 2rem 0px 0px 6rem;
  font-size: larger;
}
.anchorfoot1 {
  padding: 2rem 0px 0px 2rem;
  font-size: larger;
  
  display: flex;
  justify-content: left;
}

.pfooter {
  font-size: medium;
  display: flex;

}
.footoffice {
  padding: 10px 0px 0px 50px;
  font-size: x-large;
}
.botfoot {
  text-align: center;
  background-color: #2f3c5b;
  color: white;
  height: 2rem;
  font-size: larger;
  padding: 4px;
}
.iconfooter {
  display: flex;
  justify-content: left;
}
.icon {
  padding: 0rem 0.5rem 0rem 2rem;
  cursor: pointer;
}
#footmail{
  display: flex;
  justify-content: left;
} */




.blocks {
  display: flex;
  flex-direction: row;
  background-color: #14213d;
  color: white;
  overflow: hidden;
  padding-bottom: 1%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.block1, .block4 {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  flex: 1;
  min-width: 200px;
}

.block1 {
  margin-right: 2rem; 
}

.block4 {
  margin-left: 30rem; 
}

.block1 > img {
  background-color: white;
  width: 10rem;
  height: 4rem;
  margin: 1rem 1rem 0rem 2rem;
  object-fit: contain;
}

.block1 > p {
  width: 45rem;
  padding: 1rem 0rem 0rem 2rem;
  font-size: medium;
}

.anchor {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 200px;
  margin: 0px 10px 0px 100px;
}

.insideanch {
  font-size: medium;
  text-decoration: none;
  color: white;
  flex-direction: column;
  padding: 0.7rem 0rem 0rem 0rem;
  transition: 0.7s ease-in-out;
  transform: translateX(0rem);
}

.insideanch:hover {
  color: green;
  transform: translateX(1.5rem);
}

.anchorfoot, .anchorfoot1 {
  padding: 2rem 0px 0px 2rem;
  font-size: larger;
  display: flex;
  justify-content: left;
}

.pfooter {
  font-size: medium;
  display: flex;
}

.footoffice {
  padding: 10px 0px 0px 50px;
  font-size: x-large;
}

.botfoot {
  text-align: center;
  background-color: #2f3c5b;
  color: white;
  height: 2rem;
  font-size: larger;
  padding: 4px;
}

.iconfooter {
  display: flex;
  justify-content: left;
}

.icon {
  padding: 0rem 0.5rem 0rem 2rem;
  cursor: pointer;
}

#footmail {
  display: flex;
  justify-content: left;
}

/* Responsive Styles */

@media (max-width:1180px){
  .icon{
    padding: 0rem 0.5rem 0rem 0.5rem;
  }
}

@media (max-width:1016px){
 .block1{width: auto;
 }
 .block1>p{
  width: 25rem;
 }
 
  .remove-br{
  display: none;
 }
}

@media (max-width:944px){
  .block4{
    margin-left: 20rem;
  }
}
@media (max-width: 785px) {
  /* .block1{
    width: 10rem;
  } */
   .blocks{
    padding: 0;
   }
  .anchorfoot1{
    padding: 0rem 0rem 0rem 0.5rem;
  }
  .block4{
    margin: 0rem 0rem 0rem 2rem;
    width: 100%;
  }
  .blocks {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .block1, .block4 {
    max-width: 100%;
  }

  .block1 > p {
    width: 100%;
    padding: 1rem;
  }

  .iconfooter {
    justify-content: center;
  }
}

@media (max-width: 600px){
  .remove-br{
    display: inline;
  }
  .anchorfoot1{
    display: flex;
    justify-content: center;
  }
  .pfooter{
    display: flex;
    justify-content: center;
  }
  #footmail{
    display: flex;
    justify-content: center;
    margin: 0rem 3rem 0rem 0rem;
  }
}

@media (max-width: 480px) {
  .block1 > img {
    width: 8rem;
    height: auto;
    margin: 1rem auto;
  }

  .block1 > p {
    font-size: small;
    text-align: center;
    padding: 1rem;
  }

  .anchorfoot, .anchorfoot1 {
    justify-content: center;
    padding: 1rem 0;
  }

  .pfooter {
    font-size: small;
    text-align: center;
    justify-content: center;
  }

  .iconfooter {
    justify-content: center;
    padding: 1rem 0;
  }

  .icon {
    padding: 0rem 0.5rem;
  }

  .botfoot {
    font-size: small;
    padding: 1rem;
  }
}
