/* .nav {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.tophalf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  width: 10rem;
  padding: 0;
  margin: 0;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2rem;
}

.login-button {
  display: flex;
  align-items: center;
}

.bottomhalf {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: flex-start; 
  align-items: center;
  width: 100%;
}

.list {
  margin: 0 1rem; 
  text-align: center;
}


.bottomhalf.open {
  display: flex;
  flex-direction: column;
}



.anc {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .bottomhalf {
    display: none; 
    flex-direction: column;
  }

  .bottomhalf.open {
    display: flex;
    flex-direction: column;
  }

  .list {
    margin: 0.1rem 0; 
  }
} */


/* navbar.css */

/* .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #fff;
}

.tophalf {
  display: flex;
  align-items: center;
  width: 100%;
}

.logo {
  width: 100px;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.bottomhalf {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.bottomhalf li {
  margin-right: 1rem;
}

.bottomhalf.open {
  display: block;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.list {
  margin-bottom: 0.5rem;
}

.anc {
  text-decoration: none;
  color: #000;
}

.login-button {
  margin-left: auto;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .bottomhalf {
    display: none;
    flex-direction: column;
  }
} */


/* navbar.css */

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #fff;
  width: 98%;
}

.tophalf {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; 
}

.logo-link {
  flex-shrink: 0; 
}

.logo {
  width: 100px;
}


/* .menu-container {
  display: flex;
  align-items: center;
  margin-left: 15rem; 
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.bottomhalf {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: space-between; 
}

.bottomhalf.open {
  display: block;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.list {
  margin: 0 0.5rem; 
}

.anc {
  text-decoration: none;
  color: #000;
}

.login-button {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .bottomhalf {
    display: none;
    flex-direction: column;
  }

  .bottomhalf.open {
    display: block;
  }

  .list {
    margin-bottom: 0.5rem;
  }
} */


/* Adjust the positioning of the menu-container */
/* Adjust the positioning of the menu-container */
/* Adjust the positioning of the menu-container */
.menu-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.bottomhalf {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;
}

.bottomhalf.open {
  flex-direction: column; /* Ensure items are displayed vertically in mobile view */
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.list {
  margin: 0 1rem; /* Adjust this value to control spacing between items */
}

.anc {
  text-decoration: none;
  color: #000;
}

.login-button {
  margin-left: auto;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .bottomhalf {
    display: none;
    flex-direction: column;
  }

  .bottomhalf.open {
    display: flex;
  }

  .list {
    margin: 0.5rem 0; /* Adjust this value for spacing in mobile view */
  }
}

@media (min-width: 769px) {
  .bottomhalf {
    display: flex !important; /* Ensure the menu items are always horizontal in desktop view */
    flex-direction: row !important;
    position: static !important; /* Make sure they are not positioned absolutely in desktop view */
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
  }

  .list {
    margin: 0 1rem; /* Adjust this value for spacing in desktop view */
  }
}
