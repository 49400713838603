#contpara{
    padding: 1em 9em 1em 14em;
    font-size: medium;
}
.formup>h1{
    font-weight: 700;
    margin: 1rem 0rem 0rem 38rem;
}
/* #inputcontact{
    display: flex;
    flex-direction: column;
    margin: 3em 1em 3em 3em;
} */

 #input2contact{
    width: 15rem;
    height: 2rem;
    border-radius: 10px;
    font-size: medium;
    margin: 1rem 0rem 0rem 11rem;
   
}
#input2contact>label{
    font-size: large;
    font-weight: 500;
}
#input2contact>select{
    width: 10rem ;
    border-radius: 10px;
    border: 1px solid lightgray;
    font-size: medium;
    height: 1.8rem;
    margin: 0.5rem 0rem 0rem 0rem;
} 

#formbuttoncontact{
    margin: 1rem 0rem 2rem 36rem;
    width: 10rem;
    height: 2.5rem;
    color: white;
    background-color: green;
    border: 2px solid green;
    border-radius: 10px;
    font-size: large;
}
.css-1xd7dmx{
    display: flex;
    flex-direction: column;
}
/* .css-k008qs{
    justify-content: space-evenly;
} */
.formcontact{
    padding: 0rem 0rem 0rem 5rem;
}

.imageContact{
    
    width: 100%;
    height: 20rem;
    margin: auto;
    position: absolute;

    /* justify-content:"center"; */
    /* margin: "auto"  */
}
.contacthead{
    position: relative;
    color: white;
    /* font-size: xxx-large;
    font-size: "10rem";
    margin: "2rem 0rem 0rem 10rem"; */
    font-size: 4rem;
    padding: 6rem;
}