.mediacover > h1 {
  text-align: center;
  font-size: xxx-large;
}
.mediacover > h5 {
  text-align: center;
}
.mediacover1 {
  display: flex;
  
  background-color: aliceblue;
  padding: 6em 5em 3em 1em;
  margin: 1em 1em 1em 1em;
  border-radius: 15px;
}
.mediacover1 > img {
  margin: -3rem 0rem 0rem 0rem;
}
.mediacover11 {
  padding: 0rem 4rem 0rem 2rem;
  margin-left: 3rem;
  margin-right: 2rem;
  width: 50%;
  font-family: "Daikon-regular";
}
